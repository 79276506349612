import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Textarea } from "@mantine/core";

export default function ProgressResumeView({ applicantProgress }) {
  return (
    <Container>
      <Row>
        <Col>Admin Officer</Col>
        <Col>Technical Manager </Col>
        <Col>General Manager</Col>
      </Row>
      <Row>
        <Col>
          <Textarea
            disabled
            defaultValue={applicantProgress.resume_admin}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
        <Col>
          <Textarea
            disabled
            defaultValue={applicantProgress.resume_technical}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
        <Col>
          <Textarea
            disabled
            defaultValue={applicantProgress.resume_general}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
      </Row>
    </Container>
  );
}
