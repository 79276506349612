import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectUser } from "../../features/userSlice";

export default function PrivateRoute() {
  const user = useSelector(selectUser);
  const loggedIn = window.localStorage.getItem("loggedIn");
  console.log(user.name);
  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  }
  return <Outlet />;
}
