import { Table, Drawer, Modal } from "@mantine/core";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { PSchedAdd } from "./PSchedModal/PSchedAdd";
import { PSchedView } from "./PSchedDrawer/PschedView";
import { PSchedEdit } from "./PSchedDrawer/PSchedEdit";
import { DateFormat } from "../components/display";

/*Placeholder*/
const elements = [
  {
    ref: "GEO-ALBEDO-1",
    prf: "PRF-ID-000",
    status: "Completed",
    link: "https://goo.gl/123",
    date: "2022-06-06",
    amount: 10000,
    progress: 50,
    criteria: 50,
    employee: "Albedo Kreideprinz",
    subproject: "GEO",
  },
  {
    ref: "GEO-ALBEDO-2",
    prf: "PRF-ID-001",
    status: "Ready for Filing",
    link: "",
    date: "2022-07-06",
    amount: 10000,
    progress: 75,
    criteria: 100,
    employee: "Albedo Kreideprinz",
    subproject: "GEO",
  },
];

export function PSchedTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [opened, setOpened] = useState(false);
  const [addOpened, setAddOpened] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [schedData, setSchedData] = useState({});
  const [editContactId, setEditContactId] = useState(null);

  function clicked(
    psRef,
    psPrf,
    psLink,
    psDate,
    psAmount,
    psProgress,
    psCriteria,
    psEmployee,
    psSubproject,
    psFile,
    psRemarks
  ) {
    setOpened(true);

    let schedData = {};
    schedData["ref"] = psRef;
    schedData["prf"] = psPrf;
    schedData["link"] = psLink;
    schedData["date"] = psDate;
    schedData["amount"] = psAmount;
    schedData["progress"] = psProgress;
    schedData["criteria"] = psCriteria;
    schedData["employee"] = psEmployee;
    schedData["subproject"] = psSubproject;
    schedData["file"] = psFile;
    schedData["remarks"] = psRemarks;

    let jsonString = JSON.stringify(Object.assign({}, schedData));
    let json_obj = JSON.parse(jsonString);

    setSchedData(json_obj);

    if (psProgress < psCriteria) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function add() {
    setAddOpened(true);
  }

  function MantineTable() {
    const rows = elements
      .filter((val) => {
        if (searchTerm === "") {
          return val;
        } else if (
          val.employee.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return val;
        }
      })
      .map((element) => (
        <tr key={element.ref}>
          <td>
            <button
              className="btn-text"
              onClick={() =>
                clicked(
                  element.ref,
                  element.prf,
                  element.link,
                  element.date,
                  element.amount,
                  element.progress,
                  element.criteria,
                  element.employee,
                  element.subproject,
                  element.file,
                  element.remarks
                )
              }
            >
              {element.ref}
            </button>
          </td>
          <td>{element.prf}</td>
          <td>{element.status}</td>
          <td>
            <a href={element.link} target="_blank">
              {element.link}
            </a>
          </td>
          <td>{DateFormat(element.date)}</td>
          <td>
            <CurrencyFormat
              value={element.amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </td>
          <td>{element.progress}%</td>
          <td>{element.criteria}%</td>
          <td>{element.employee}</td>
          <td>{element.subproject}</td>
        </tr>
      ));

    return (
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>Reference No. </th>
            <th>PRF No.</th>
            <th>Status</th>
            <th>Link</th>
            <th>Target Date</th>
            <th>Amount</th>
            <th>Progress</th>
            <th>Criteria</th>
            <th>Employee</th>
            <th>Subproject</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }
  const handleEditClick = (event, schedData) => {
    event.preventDefault();
    setEditContactId(schedData.ref);
  };
  const handleCancelClick = (event) => {
    event.preventDefault();
    setEditContactId(null);
  };

  return (
    <>
      <Row>
        <Col md={10}>
          <Input
            icon={<IconSearch size={16} />}
            placeholder="Search..."
            radius="lg"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </Col>
        <Col md={2} className="ps-right">
          <button className="btn-primary" onClick={() => add()}>
            Add Payment
          </button>
        </Col>
      </Row>
      <MantineTable />
      <Drawer
        position="right"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Payment Schedule"
        padding="xl"
        size="xl"
      >
        {editContactId === schedData.ref ? (
          <PSchedEdit
            scheduleData={schedData}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <PSchedView
            scheduleData={schedData}
            disabled={disabled}
            handleEditClick={handleEditClick}
          />
        )}
      </Drawer>
      <Modal
        opened={addOpened}
        onClose={() => setAddOpened(false)}
        title="Add New Payment Schedule"
      >
        <PSchedAdd />
      </Modal>
    </>
  );
}
