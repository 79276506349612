import { Tabs, TextInput, Select } from "@mantine/core";
import Avatar from "react-avatar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DatePicker } from "@mantine/dates";
import { DateFormat, payLabel, payValue } from "../../components/display";

const employee_types = [
  { value: "Full-time (Regular)", label: "Full-time (Regular)" },
  { value: "Part-time (Regular)", label: "Part-time (Regular)" },
  { value: "Part-time (Project Based)", label: "Part-time (Project Based)" },
];

const teams = [
  { value: "Technical", label: "Technical" },
  { value: "Management", label: "Management" },
];

const positions = [
  { value: "Project Manager", label: "Project Manager" },
  { value: "Front-end Developer", label: "Front-end Developer" },
  { value: "Back-end Developer", label: "Back-end Developer" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "QA Engineer", label: "QA Engineer" },
  { value: "IoT Engineer", label: "IoT Engineer" },
];

const employee_list = [
  { value: "Diluc Ragnvindr", label: "Diluc Ragnvindr" },
  { value: "Lisa Minci", label: "Lisa Minci" },
  { value: "Ayato Kamisato", label: "Ayato Kamisato" },
  { value: "Jean Gunnhildr", label: "Jean Gunnhildr" },
  { value: "Venti Barbatos", label: "Venti Barbatos" },
  { value: "Zhongli Morax", label: "Zhongli Morax" },
];

export function EmployeesEdit({
  employeeData,
  handleCancelClick,
  handleChange,
}) {
  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <Avatar
              name={employeeData.name}
              size="110"
              round={true}
              textSizeRatio={3}
            />
          </Col>
          <Col>
            <Row>
              <Col className="info-title subheading">Name</Col>
            </Row>
            <Row>
              <Col className="info-name heading3">
                <TextInput
                  variant={"filled"}
                  defaultValue={employeeData.name}
                />
              </Col>
            </Row>
            <Row>
              <Col className="info-title subheading">ID</Col>
            </Row>
            <Row>
              <Col className="info-name heading2" md={2}>
                ZS-
              </Col>
              <Col className="info-name heading3">
                <TextInput
                  variant={"filled"}
                  defaultValue={employeeData.id.substring(3)}
                />
              </Col>
            </Row>
            <Row>
              <Col className="info-title subheading">
                Added on {DateFormat(employeeData.date_added)}
              </Col>
            </Row>
          </Col>
          <Col className="emp-right"></Col>
        </Row>
        <Row>
          <Col>
            <Tabs defaultValue="general">
              <Tabs.List>
                <Tabs.Tab value="general">General Information</Tabs.Tab>
                <Tabs.Tab value="bank">Bank Details</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="general" pt="xs">
                <Container>
                  <Row>
                    <Col className="info-title subheading">Position</Col>
                    <Col className="info-title subheading">Team</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <Select
                        searchable
                        defaultValue={employeeData.position}
                        data={positions}
                      />
                    </Col>
                    <Col className="info-data body-sb">
                      <Select
                        searchable
                        defaultValue={employeeData.team}
                        data={teams}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Email</Col>
                    <Col className="info-title subheading">Employee Type</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.email}
                      />
                    </Col>
                    <Col className="info-data body-sb">
                      <Select
                        searchable
                        defaultValue={employeeData.type}
                        data={employee_types}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Phone Number</Col>
                    <Col className="info-title subheading">
                      {payLabel(employeeData.type)}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.phone}
                      />
                    </Col>
                    <Col className="info-data body-sb">
                      {payValue(
                        employeeData.type,
                        employeeData.basic_pay,
                        employeeData.hourly_rate,
                        false
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Birthdate</Col>
                    <Col className="info-title subheading">Referred By</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <DatePicker
                        defaultValue={new Date(employeeData.birthday)}
                      />
                    </Col>
                    <Col className="info-data body-sb">
                      <Select
                        searchable
                        defaultValue={employeeData.referred}
                        data={employee_list}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading" md={6}>
                      HR Drive
                    </Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb" md={6}>
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.file}
                      />
                    </Col>
                  </Row>
                </Container>
              </Tabs.Panel>

              <Tabs.Panel value="bank" pt="xs">
                <Container>
                  <Row>
                    <Col className="info-title subheading">Bank Name</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.bank_name}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Account Name</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.acc_name}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Account Number</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <TextInput
                        variant={"filled"}
                        defaultValue={employeeData.acc_num}
                      />
                    </Col>
                  </Row>
                </Container>
              </Tabs.Panel>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col className="emp-right">
            <button className="btn-primary" onClick={handleChange}>
              Save
            </button>
            <button
              className="btn-secondary"
              onClick={(event) => handleCancelClick(event)}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
