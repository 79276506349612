import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ApplicantsArchiveHead } from "../ApplicantsArchive/ApplicantsArchiveHead";
import { ApplicantsArchiveTable } from "../ApplicantsArchive/ApplicantsArchiveTable";

export function ApplicantsArchive() {
  return (
    <Container>
      <Row>
        <Col>
          <ApplicantsArchiveHead />
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicantsArchiveTable />
        </Col>
      </Row>
    </Container>
  );
}
