import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useState } from "react";
import { TextInput, Select } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";
import { DatePicker, DateRangePicker } from "@mantine/dates";

export function ArchiveDialog({ name, setArchive, setAdd }) {
  return (
    <>
      <Container>
        <Row>
          <Col>Are you sure you want to archive {name}?</Col>
        </Row>
        <Row>
          <Col className="app-right">
            <button className="btn-primary">Yes</button>
            <button className="btn-secondary" onClick={() => setArchive(false)}>
              No
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export function AddEmployeeDialog({ name, setAdd }) {
  return (
    <>
      <Container>
        <Row>
          <Col>Are you sure you want to add {name} as an employee?</Col>
        </Row>
        <Row>
          <Col className="app-right">
            <button className="btn-primary">Yes</button>
            <button className="btn-secondary" onClick={() => setAdd(false)}>
              No
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export function DeleteEmployeeDialog({ name, setDeleteEmp }) {
  return (
    <>
      <Container>
        <Row>
          <Col>Are you sure you want to delete {name}'s records?</Col>
        </Row>
        <Row>
          <Col className="app-right">
            <button className="btn-primary">Yes</button>
            <button
              className="btn-secondary"
              onClick={() => setDeleteEmp(false)}
            >
              No
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export function AddNewPaySlip({ employeeData, setAdd }) {
  const [cutoff, setCutoff] = useState([new Date(), new Date()]);

  const teams = [
    { value: "Technical", label: "Technical" },
    { value: "Management", label: "Management" },
  ];

  const positions = [
    { value: "Project Manager", label: "Project Manager" },
    { value: "Front-end Developer", label: "Front-end Developer" },
    { value: "Back-end Developer", label: "Back-end Developer" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "QA Engineer", label: "QA Engineer" },
    { value: "IoT Engineer", label: "IoT Engineer" },
  ];

  const [additionalEarningField, setAdditionalEarningField] = useState([]);
  const [additionalDeductionField, setAdditionalDeductionField] = useState([]);

  const handleAdditionalEarningFieldAdd = () => {
    setAdditionalEarningField([
      ...additionalEarningField,
      { additionalField: "" },
    ]);
  };

  const handleAdditionalDeductionFieldAdd = () => {
    setAdditionalDeductionField([
      ...additionalDeductionField,
      { additionalField: "" },
    ]);
  };

  function hourLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>Total Working Hours</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return <>Hourly Rate</>;
    } else {
      return <></>;
    }
  }

  function payLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>Basic Pay</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return <>Monthly Pay</>;
    } else {
      return <></>;
    }
  }

  function defaultEarnings() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">Allowance</Row>
          <Row className="info-data body-sb">
            <TextInput />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  function defaultDeductions() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">SSS</Row>
          <Row className="info-data body-sb">
            <TextInput />
          </Row>
          <Row className="info-title subheading">HDMF</Row>
          <Row className="info-data body-sb">
            <TextInput />
          </Row>
          <Row className="info-title subheading">PhilHealth</Row>
          <Row className="info-data body-sb">
            <TextInput />
          </Row>
          <Row className="info-title subheading">Withholding Taxes</Row>
          <Row className="info-data body-sb">
            <TextInput />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Row className="info-title subheading">Payslip For</Row>
            <Row className="info-name heading3">
              <DatePicker defaultValue={new Date()} />
            </Row>
          </Col>
          <Col>
            <Row className="info-title subheading">Cutoff</Row>
            <Row className="info-name heading3">
              <DateRangePicker value={cutoff} onChange={setCutoff} />
            </Row>
          </Col>
        </Row>
        <Row className="info-data heading3">Basic Information</Row>
        <Row>
          <Col>
            <Row className="info-title subheading">Position</Row>
            <Row className="info-data body-sb">
              <Select
                searchable
                defaultValue={employeeData.position}
                data={positions}
              />
            </Row>
          </Col>
          <Col>
            <Row className="info-title subheading">Team</Row>
            <Row className="info-data body-sb">
              <Select
                searchable
                defaultValue={employeeData.team}
                data={teams}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="info-title subheading">Hours Worked</Row>
            <Row className="info-data body-sb">
              <TextInput />
            </Row>
          </Col>
          <Col>
            <Row className="info-title subheading">{hourLabel()}</Row>
            <Row className="info-data body-sb">
              <TextInput />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="info-data heading3">Earnings</Row>
            <Row>
              <Row className="info-title subheading">{payLabel()}</Row>
              <Row className="info-data body-sb">
                <TextInput />
              </Row>
              {defaultEarnings()}
              {additionalEarningField.map((singleEarningField, index) => (
                <>
                  <Row className="info-title subheading">
                    <TextInput
                      placeholder="Additional Earning Name"
                      size="xs"
                    />
                  </Row>
                  <Row className="info-data body-sb">
                    <TextInput placeholder="Additional Earning Value" />
                  </Row>
                </>
              ))}
              <Row>
                <Col className="d-flex justify-content-center">
                  <button
                    className="btn-primary"
                    onClick={handleAdditionalEarningFieldAdd}
                  >
                    <IconCirclePlus /> Add Earning
                  </button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col>
            <Row className="info-data heading3"> Deductions</Row>
            {defaultDeductions()}
            {additionalDeductionField.map((singleDeductionField, index) => (
              <>
                <Row className="info-title subheading">
                  <TextInput
                    placeholder="Additional Deduction Name"
                    size="xs"
                  />
                </Row>
                <Row className="info-data body-sb">
                  <TextInput placeholder="Additional Deduction Value" />
                </Row>
              </>
            ))}
            <Row>
              <Col className="d-flex justify-content-center">
                <button
                  className="btn-primary"
                  onClick={handleAdditionalDeductionFieldAdd}
                >
                  <IconCirclePlus /> Add Deduction
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="app-right">
            <button className="btn-primary">Save</button>
            <button className="btn-secondary" onClick={() => setAdd(false)}>
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
