import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

export function EmployeesArchiveHead() {
  const items = [
    { title: "Employee List", href: "/employee" },
    { title: "Employee Archive", href: "/employee/employee-archive" },
  ].map((item, index) => (
    <Link to={item.href} key={index} className="heading3 remove-deco">
      {item.title}
    </Link>
  ));

  return (
    <>
      <Row>
        <Col className="emp-left">
          <h2>
            <Breadcrumbs>{items}</Breadcrumbs>
          </h2>

          <h2 className="subheading">
            This is the list of all archived employees
          </h2>
        </Col>
      </Row>
    </>
  );
}
