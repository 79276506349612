import React from "react";
import { SideNav } from "./SideNav";
import { Outlet } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
export default function AppLayout() {
  return (
    <>
      <Row>
        <Col md={3}>
          <SideNav />
        </Col>
        <Col md={9}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}
