import { Table, Drawer } from "@mantine/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { useState } from "react";
import Avatar from "react-avatar";
import "../../App.css";
import { EmployeesView } from "./EmployeesDrawer/EmployeesView";
import { EmployeesEdit } from "./EmployeesDrawer/EmployeesEdit";

/*Placeholder*/
const elements = [
  {
    id: "ZS-010",
    name: "Ayato Kamisato",
    position: "Front-end Developer",
    team: "Technical",
    birthday: "1990-03-26",
    type: "Part-time (Regular)",
    date_added: "2020-06-08",
    email: "bobatea@kamisato.com",
    phone: "639285627893",
    hourly_rate: "420",
    referred: "Diluc Ragnvindr",
    files: "https://wdrive.com/122",
    bank: "Northland Bank",
    acc_name: "Ayato Kamisato",
    acc_num: "156458954893",
  },
  {
    id: "ZS-011",
    date_added: "2020-06-09",
    name: "Jean Gunnhildr",
    position: "Project Manager",
    team: "Technical",
    birthday: "1985-03-14",
    type: "Full-time (Regular)",
    email: "jean@knights.com",
    phone: "639285627894",
    basic_pay: "19420",
    referred: "Lisa Minci",
    files: "https://wdrive.com/123",
    bank: "Northland Bank",
    acc_name: "Jean Gunnhildr",
    acc_num: "156458954894",
  },
  {
    id: "ZS-012",
    name: "Venti Barbatos",
    team: "Technical",
    birthday: "1999-06-16",
    position: "UX/UI Designer",
    type: "Part-time (Project Based)",
    date_added: "2020-06-10",
    email: "ventithebard@dvalin.com",
    phone: "639285627993",
    hourly_rate: "420",
    referred: "Diluc Ragnvindr",
    files: "https://wdrive.com/124",
    bank: "Northland Bank",
    acc_name: "Venti Barbatos",
    acc_num: "156458954895",
  },
  {
    id: "ZS-013",
    name: "Zhongli Morax",
    team: "Technical",
    position: "Back-end Developer",
    birthday: "1970-12-31",
    type: "Full-time (Regular)",
    date_added: "2020-07-25",
    email: "guizhong@liyue.com",
    phone: "639285628893",
    basic_pay: "19420",
    referred: "Venti Barbatos",
    files: "https://wdrive.com/125",
    bank: "Northland Bank",
    acc_name: "Childe Tartaglia",
    acc_num: "156458954873",
  },
];

export function EmployeesTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [opened, setOpened] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [editContactId, setEditContactId] = useState(null);
  // const [editData, setEditData] = useState({
  //   name: "",
  // });

  // const handleEdit = (event) => {
  //   event.preventDefault();

  //   const fieldName = event.target.getAttribute("name");
  //   const fieldValue = event.target.defaultValue;

  //   const newData = { editData };
  //   newData[editData] = fieldValue;

  //   setEditData(newData);
  // };

  function clicked(
    empAdded,
    empName,
    empID,
    empPosition,
    empTeam,
    empType,
    empEmail,
    empPhone,
    empBday,
    empFile,
    empBPay,
    empHRate,
    empReferred,
    empBank,
    empAccName,
    empAccNum
  ) {
    setOpened(true);

    let empData = {};
    empData["date_added"] = empAdded;
    empData["name"] = empName;
    empData["id"] = empID;
    empData["position"] = empPosition;
    empData["team"] = empTeam;
    empData["type"] = empType;
    empData["email"] = empEmail;
    empData["phone"] = empPhone;
    empData["birthday"] = empBday;
    empData["file"] = empFile;
    empData["basic_pay"] = empBPay;
    empData["hourly_rate"] = empHRate;
    empData["referred"] = empReferred;
    empData["bank_name"] = empBank;
    empData["acc_name"] = empAccName;
    empData["acc_num"] = empAccNum;

    let jsonString = JSON.stringify(Object.assign({}, empData));
    let json_obj = JSON.parse(jsonString);

    setEmployeeData(json_obj);
  }

  function MantineTable() {
    const rows = elements
      .filter((val) => {
        if (searchTerm === "") {
          return val;
        } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val;
        }
      })
      .map((element) => (
        <tr key={element.name}>
          <td>
            <button
              onClick={() =>
                clicked(
                  element.date_added,
                  element.name,
                  element.id,
                  element.position,
                  element.team,
                  element.type,
                  element.email,
                  element.phone,
                  element.birthday,
                  element.files,
                  element.basic_pay,
                  element.hourly_rate,
                  element.referred,
                  element.bank,
                  element.acc_name,
                  element.acc_num
                )
              }
              className="btn-text"
            >
              {element.id}
            </button>
          </td>
          <td>
            <div className="name-cell">
              <Avatar name={element.name} size="36" round={true} />
              {element.name}
            </div>
          </td>
          <td>{element.position}</td>
          <td>{element.type}</td>
        </tr>
      ));

    return (
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Position</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  const handleEditClick = (event, employeeData) => {
    event.preventDefault();
    setEditContactId(employeeData.id);
  };
  const handleCancelClick = (event) => {
    event.preventDefault();
    setEditContactId(null);
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            icon={<IconSearch size={16} />}
            placeholder="Search..."
            radius="lg"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </Col>
      </Row>
      <MantineTable />
      <Drawer
        position="right"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Employee Info"
        padding="xl"
        size="xl"
      >
        {editContactId === employeeData.id ? (
          <EmployeesEdit
            employeeData={employeeData}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <EmployeesView
            handleEditClick={handleEditClick}
            employeeData={employeeData}
          />
        )}
      </Drawer>
    </>
  );
}
