import ProgressBar from "@ramonak/react-progress-bar";
import CurrencyFormat from "react-currency-format";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "@mantine/core";
import { AddEmployeeDialog, ArchiveDialog } from "../../components/Modals";
import { progressCheck } from "../../components/display";

export function ApplicantsView({ applicantData, handleEditClick }) {
  const [archive, setArchive] = useState(false);
  const [add, setAdd] = useState(false);

  function archiveApplicant() {
    setArchive(true);
  }

  function addEmployee() {
    setAdd(true);
  }

  return (
    <>
      <Container>
        <Row className="app-head">
          <Col md={5}>
            <Row>
              <Col className="info-title subheading">Name</Col>
            </Row>
            <Row>
              <Col className="info-name heading3">{applicantData.name}</Col>
            </Row>
            <Row>
              <Col className="info-title subheading">{applicantData.id}</Col>
            </Row>
            <Row>
              <Col className="info-title subheading">
                Added on{" "}
                {new Date(applicantData.date_added).toDateString().substring(4)}
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <Row>
              <Link
                className="btn-text"
                to={`/applicant/${applicantData.id}/progress`}
              >
                <ProgressBar
                  completed={applicantData.status}
                  width={200}
                  customLabel={" "}
                  bgColor={"#3cc2dd"}
                  baseBgColor={"#e8e8e8"}
                />
              </Link>
            </Row>
            <Row>
              <Link
                className="btn-text"
                to={`/applicant/${applicantData.id}/progress`}
              >
                {progressCheck(applicantData.status)}
              </Link>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col className="info-title subheading">Position</Col>
                <Col className="info-title subheading">Applicant Type</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">
                  {applicantData.position}
                </Col>
                <Col className="info-data body-sb">{applicantData.type}</Col>
              </Row>

              <Row>
                <Col className="info-title subheading">Email</Col>
                <Col className="info-title subheading">Referred by</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">{applicantData.email}</Col>
                <Col className="info-data body-sb">
                  {applicantData.referred}
                </Col>
              </Row>

              <Row>
                <Col className="info-title subheading">Phone Number</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">
                  <CurrencyFormat
                    value={applicantData.phone}
                    displayType={"text"}
                    format={"+## ### ### ####"}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="app-right">
            <button className="btn-archive" onClick={() => archiveApplicant()}>
              Archive Applicant
            </button>
            <button className="btn-primary" onClick={() => addEmployee()}>
              Add as Employee
            </button>

            <button
              className="btn-secondary"
              onClick={(event) => handleEditClick(event, applicantData)}
            >
              Edit Information
            </button>
          </Col>
        </Row>
      </Container>

      <Modal
        centered
        opened={archive}
        onClose={() => setArchive(false)}
        title="Archive Applicant"
      >
        <ArchiveDialog name={applicantData.name} setArchive={setArchive} />
      </Modal>

      <Modal
        centered
        opened={add}
        onClose={() => setAdd(false)}
        title="Add as Employee"
      >
        <AddEmployeeDialog name={applicantData.name} setAdd={setAdd} />
      </Modal>
    </>
  );
}
