import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tabs, HoverCard } from "@mantine/core";
import "../../../App.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router-dom";
import ProgressResumeView from "./ProgressTabs/ProgressResumeView";
import ProgressInitialView from "./ProgressTabs/ProgressInitialView";
import ProgressTechnicalView from "./ProgressTabs/ProgressTechnicalView";
import ProgressFinalView from "./ProgressTabs/ProgressFinalView";
import { progressCheck } from "../../components/display";
import CurrencyFormat from "react-currency-format";

export default function ProgressView() {
  let { id } = useParams();
  const applicantData = {
    id: "ZS-010A",
    name: "Ayaka Kamisato",
    type: "Full-time (Regular)",
    position: "Front-end Developer",
    email: "shirasagi@kamisato.com",
    phone: 63912345678,
    referred: "Ayato Kamisato",
  };

  const applicantProgress = {
    files: "https://www.wdrive.com/987",
    resume_admin: "Good",
    resume_technical: "Good",
    resume_general: "Good",
    initial_schedule: "2022-08-07",
    initial_assessment: "Very Good",
    technical_schedule: "2022-08-14",
    technical_assessment: "Very Good",
    technical_score: 100,
    final_schedule: "2022-08-21",
    final_assessment: "Very Good",
  };

  return (
    <Container>
      <Row>
        <Col md={9}>
          <Row>
            <Col className="info-title subheading">Name</Col>
          </Row>
          <Row>
            <Col className="info-name heading3">{applicantData.name}</Col>
          </Row>
          <Row>
            <Col className="info-title subheading">{id}</Col>
          </Row>
        </Col>
        <Col md={3}>
          <HoverCard shadow="md" openDelay={500}>
            <HoverCard.Target>
              <div>
                <ProgressBar
                  completed={50}
                  height={10}
                  customLabel={" "}
                  bgColor={"#3cc2dd"}
                  baseBgColor={"#e8e8e8"}
                />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown>{progressCheck(50)}</HoverCard.Dropdown>
          </HoverCard>
        </Col>
      </Row>
      <Row>
        <Col className="info-padding" md={4}>
          <Row className="info-title subheading">Position</Row>
          <Row className="info-data body-sb">{applicantData.position}</Row>
          <Row className="info-title subheading">Email Address</Row>
          <Row className="info-data body-sb">{applicantData.email}</Row>
        </Col>
        <Col className="info-padding" md={4}>
          <Row className="info-title subheading">Employee Type</Row>
          <Row className="info-data body-sb">{applicantData.type}</Row>
          <Row className="info-title subheading">Phone Number</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={applicantData.phone}
              displayType={"text"}
              format={"+## ### ### ####"}
            />
          </Row>
        </Col>
        <Col className="info-padding" md={4}>
          <Row className="info-title subheading">Referred by</Row>
          <Row className="info-data body-sb">{applicantData.referred}</Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultValue="resume-review" className="info-padding ">
            {/* Still no classname for container fill */}
            <Tabs.List className="display-flex">
              <Tabs.Tab value="resume-review">Resume Review</Tabs.Tab>
              <Tabs.Tab value="initial-interview">Initial Interview</Tabs.Tab>
              <Tabs.Tab value="technical-interview">
                Technical Interview
              </Tabs.Tab>
              <Tabs.Tab value="final-interview">Final Interview</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="resume-review" pt="md" className="center-align">
              <ProgressResumeView applicantProgress={applicantProgress} />
            </Tabs.Panel>

            <Tabs.Panel value="initial-interview" pt="xs">
              <ProgressInitialView applicantProgress={applicantProgress} />
            </Tabs.Panel>
            <Tabs.Panel value="technical-interview" pt="xs">
              <ProgressTechnicalView applicantProgress={applicantProgress} />
            </Tabs.Panel>
            <Tabs.Panel value="final-interview" pt="xs">
              <ProgressFinalView applicantProgress={applicantProgress} />
            </Tabs.Panel>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
