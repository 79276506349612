import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export function EmployeesHead() {
  return (
    <>
      <Row>
        <Col className="emp-left">
          <h1 className="heading3">Employee List</h1>
          <h2 className="subheading">This is the list of all employees</h2>
        </Col>
        <Col className="emp-right">
          <Link className="btn-primary" to={"/employee/employee-archive"}>
            View Employee Archive
          </Link>
        </Col>
      </Row>
    </>
  );
}
