import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ApplicantsHead } from "../Applicants/ApplicantsHead";
import { ApplicantsTable } from "../Applicants/ApplicantsTable";

export function Applicants() {
  return (
    <Container>
      <Row>
        <Col>
          <ApplicantsHead />
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicantsTable />
        </Col>
      </Row>
    </Container>
  );
}
