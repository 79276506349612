import { TextInput, Select } from "@mantine/core";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const employee_types = [
  { value: "Full-time (Regular)", label: "Full-time (Regular)" },
  { value: "Part-time (Regular)", label: "Part-time (Regular)" },
  { value: "Part-time (Project Based)", label: "Part-time (Project Based)" },
];

const positions = [
  { value: "Project Manager", label: "Project Manager" },
  { value: "Front-end Developer", label: "Front-end Developer" },
  { value: "Back-end Developer", label: "Back-end Developer" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "QA Engineer", label: "QA Engineer" },
  { value: "IoT Engineer", label: "IoT Engineer" },
];

const employee_list = [
  { value: "Diluc Ragnvindr", label: "Diluc Ragnvindr" },
  { value: "Lisa Minci", label: "Lisa Minci" },
  { value: "Ayato Kamisato", label: "Ayato Kamisato" },
  { value: "Jean Gunnhildr", label: "Jean Gunnhildr" },
  { value: "Venti Barbatos", label: "Venti Barbatos" },
  { value: "Zhongli Morax", label: "Zhongli Morax" },
];

export function ApplicantsEdit({ applicantData, handleCancelClick }) {
  return (
    <>
      <Container>
        <Row className="app-head">
          <Col>
            <Row>
              <Col className="info-title subheading">Name</Col>
            </Row>
            <Row>
              <TextInput variant={"filled"} defaultValue={applicantData.name} />
            </Row>
            <Row>
              <Col className="info-title subheading">{applicantData.id}</Col>
            </Row>
            <Row>
              <Col className="info-title subheading">
                Added on{" "}
                {new Date(applicantData.date_added).toDateString().substring(4)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col className="info-title subheading">Position</Col>
                <Col className="info-title subheading">Applicant Type</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">
                  <Select
                    searchable
                    defaultValue={applicantData.position}
                    data={positions}
                  />
                </Col>
                <Col className="info-data body-sb">
                  <Select
                    searchable
                    defaultValue={applicantData.type}
                    data={employee_types}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="info-title subheading">Email</Col>
                <Col className="info-title subheading">Referred by</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">
                  <TextInput
                    variant={"filled"}
                    defaultValue={applicantData.email}
                  />
                </Col>
                <Col className="info-data body-sb">
                  <Select
                    searchable
                    defaultValue={applicantData.referred}
                    data={employee_list}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="info-title subheading">Phone Number</Col>
              </Row>
              <Row>
                <Col className="info-data body-sb">
                  <TextInput
                    variant={"filled"}
                    defaultValue={applicantData.phone}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="app-right">
            <button className="btn-primary">Save</button>

            <button
              className="btn-secondary"
              onClick={(event) => handleCancelClick(event)}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
