import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";

export function DashboardGreeting() {
  const user = useSelector(selectUser);
  const applicant_count = 4;
  const name = "Diluc";
  const upcoming_count = 2;
  return (
    <>
      <header className="greetings">
        <h1 className="heading2">
          Hello {localStorage.getItem("loggedIn", user)}!
        </h1>
        <p className="body-def">
          You have {applicant_count} applicants today. You also have{" "}
          {upcoming_count} upcoming scheduled payments.
          {/* {getMondays()} */}
        </p>
      </header>
    </>
  );
}
