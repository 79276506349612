import { Table } from "@mantine/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { DateFormat } from "../components/display";

export function PayslipsTable({ elements }) {
  function MantineTable() {
    const rows = elements.map((element) => (
      <tr key={element.id}>
        <td>
          <Link
            className="btn-text"
            to={`/employee/${element.id}/payslip/${element.release}`}
          >
            {DateFormat(element.release)}
          </Link>
        </td>
        <td>
          {DateFormat(element.cutoff_start)} - {DateFormat(element.cutoff_end)}
        </td>
        <td>{element.status}</td>
        <td>{element.hours_worked}</td>
        <td>
          <CurrencyFormat
            value={element.net_pay}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </td>
      </tr>
    ));

    return (
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>Release Date</th>
            <th>Cut-off Period</th>
            <th>Status</th>
            <th>Hours Worked</th>
            <th>Net Pay</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  return (
    <>
      <MantineTable />
    </>
  );
}
