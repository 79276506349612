import { Text, TextInput } from "@mantine/core";
import CurrencyFormat from "react-currency-format";

export function progressCheck(progress) {
  switch (progress) {
    case 100:
      return <Text size="sm">Passed Final Interview</Text>;
    case 75:
      return <Text size="sm">Passed Technical Interview</Text>;
    case 50:
      return <Text size="sm">Passed Initial Interview</Text>;
    case 25:
      return <Text size="sm">Passed Resume Review</Text>;
    case 0:
      return <Text size="sm">Up for Resume Review</Text>;
    default:
      return <Text size="sm">Data unavailable</Text>;
  }
}

export function DateFormat(date) {
  return new Date(date).toDateString().substring(4);
}

export function payLabel(employeeType) {
  if (employeeType === "Full-time (Regular)") {
    return (
      <>
        <div>Basic Pay</div>
      </>
    );
  } else if (employeeType === "Part-time (Regular)") {
    return (
      <>
        <div>Hourly Rate</div>
      </>
    );
  } else {
    return <></>;
  }
}

export function payValue(employeeType, basicPay, hourlyRate, readOnly) {
  if (employeeType === "Full-time (Regular)") {
    return readOnly ? (
      <>
        <div>
          <CurrencyFormat
            value={basicPay}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </>
    ) : (
      <>
        <div>
          <TextInput variant={"filled"} defaultValue={basicPay} />
        </div>
      </>
    );
  } else if (employeeType === "Part-time (Regular)") {
    return readOnly ? (
      <>
        <div>
          <CurrencyFormat
            value={hourlyRate}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
      </>
    ) : (
      <>
        <div>
          <TextInput variant={"filled"} defaultValue={hourlyRate} />
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
