import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  Anchor,
} from "@mantine/core";
import { useState, useContext } from "react";
import zipher_logo from "../../img/zipher-logo.png";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../Account";

export function Login() {
  const [error, setError] = useState(null);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const { authenticate } = useContext(AccountContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      login({
        name: username,
        password: password,
        loggedIn: true,
      })
    );

    authenticate(username, password)
      .then((data) => {
        console.log("Logged in!", data);
        navigate("/dash");
        localStorage.setItem("loggedIn", username);
      })
      .catch((err) => {
        console.error("Failed to login", err);
        setError("Username or Password not found!");
      });

    // if (username == "Test" && password == "Test") {
    //   window.localStorage.setItem("loggedIn", true);
    //   navigate("/dash");
    // } else {
    //   setError("Not Found!");
    // }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          <img src={zipher_logo} alt="zipher-logo" />
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Username"
            placeholder="Your username"
            value={username}
            onChange={(e) => setusername(e.target.value)}
            error={error}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            error={error}
          />
          {/* <Anchor href="/forgot" size="sm">
            Forgot password?
          </Anchor> */}
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Paper>
      </Container>
    </form>
  );
}
