import { Table } from "@mantine/core";
import { MantineCalendar } from "./DashboardCalendar";

const upcomingPayment = [
  { name: "Albedo Kreideprinz", date: "2022 September 12" },
  { name: "Klee Dodoco", date: "2022 September 16" },
];

function UpcomingTable() {
  const rows = upcomingPayment.map((upcoming) => (
    <tr>
      <td>{upcoming.name}</td>
      <td>{upcoming.date}</td>
    </tr>
  ));

  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th colSpan={2} align={"center"}>
            Upcoming Payments
          </th>
        </tr>
        <tr>
          <th>Employee</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export function DashboardPSched() {
  return <>{/* <UpcomingTable /> */}</>;
}
