import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useParams } from "react-router-dom";
import { PayslipHead } from "../PaySlips/PayslipHead";
import { PayslipsTable } from "../PaySlips/PayslipTable";
import PayslipView from "../PaySlips/PayslipView";

export function Payslips() {
  let { id } = useParams();

  // employee pay slip data where employee_id = id
  const elements = [
    {
      id: "ZS-010",
      name: "Ayato Kamisato",
      release: "2022-08-01",
      cutoff_start: "2022-07-16",
      cutoff_end: "2022-07-31",
      status: "Sent",
      hours_worked: 35,
      net_pay: 12500,
    },
    {
      id: "ZS-010",
      name: "Ayato Kamisato",
      release: "2022-08-16",
      cutoff_start: "2022-08-01",
      cutoff_end: "2022-08-15",
      status: "Draft",
      hours_worked: 35,
      net_pay: 16500,
    },
  ];
  return (
    <Container>
      <Row>
        <Col>
          <PayslipHead id={id} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PayslipsTable elements={elements} />
        </Col>
      </Row>
      {/* <Row>
        <PayslipView id="ZS-010" name="Ayato Kamisato" />
      </Row> */}
    </Container>
  );
}
