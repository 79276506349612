import { MantineProvider, Text } from "@mantine/core";
import { Dashboard } from "./components/MainPages/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Employees } from "./components/MainPages/Employees";
import { Applicants } from "./components/MainPages/Applicants";
import { PaymentSchedule } from "./components/MainPages/PaymentSchedule";
import { Login } from "./components/MainPages/Login";
import { ProgressViewHead } from "./components/Applicants/ProgressViewHead";
import { EmployeesArchive } from "./components/MainPages/EmployeesArchive";
import { ApplicantsArchive } from "./components/MainPages/ApplicantsArchive";
import { Payslips } from "./components/MainPages/Payslips";
import PayslipView from "./components/PaySlips/PayslipView";
import PayslipEdit from "./components/PaySlips/PayslipEdit";
import AppLayout from "./layout/AppLayout";
import PrivateRoute from "./components/PrivateRoute";
import { Provider } from "react-redux";
import store from "./components/app/store";
import { ForgotPassword } from "./components/MainPages/ForgotPassword";
import { Account } from "./Account";

export default function App() {
  return (
    <Provider store={store}>
      <Account>
        <MantineProvider
          theme={{ fontFamily: "Archivo" }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Router>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<AppLayout />}>
                  <Route exact path="/dash" element={<Dashboard />} />
                  <Route path="/employee" element={<Employees />} />
                  <Route path="/payment" element={<PaymentSchedule />} />
                  <Route path="/applicant" element={<Applicants />}></Route>
                  <Route
                    path="/applicant/:id/progress"
                    element={<ProgressViewHead />}
                  ></Route>
                  <Route
                    path="/employee/:id/payslip"
                    element={<Payslips />}
                  ></Route>
                  <Route
                    path="/employee/:id/payslip/:release"
                    element={<PayslipView />}
                  />

                  <Route
                    path="/employee/employee-archive"
                    element={<EmployeesArchive />}
                  ></Route>
                  <Route
                    path="/applicant/app-archive"
                    element={<ApplicantsArchive />}
                  ></Route>
                  <Route
                    path="/employee/:id/payslip/:release/edit"
                    element={<PayslipEdit />}
                  ></Route>
                </Route>
              </Route>
            </Routes>
          </Router>
        </MantineProvider>
      </Account>
    </Provider>
  );
}
