import { useState } from "react";
import { Calendar } from "@mantine/dates";
import { Table } from "@mantine/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../App.css";

import { DashboardApplicants } from "../Dashboard/DashboardApplicants";
import { DashboardPSched } from "../Dashboard/DashboardPSched";
import { DashboardGreeting } from "../Dashboard/DashboardGreeting";
import { DashboardCalendar } from "../Dashboard/DashboardCalendar";

// const upcomingPayment = [
//   { name: "Albedo Kreideprinz", date: "2022 September 12" },
//   { name: "Klee Dodoco", date: "2022 September 16" },
// ];
// function UpcomingTable() {
//   const rows = upcomingPayment.map((upcoming) => (
//     <tr>
//       <td>{upcoming.name}</td>
//       <td>{upcoming.date}</td>
//     </tr>
//   ));

//   return (
//     <Table>
//       <thead>
//         <tr>
//           <th>Employee</th>
//           <th>Date</th>
//         </tr>
//       </thead>
//       <tbody>{rows}</tbody>
//     </Table>
//   );
// }

// function MantineCalendar() {
//   const [value, setValue] = useState(null);
//   return <Calendar value={value} onChange={setValue} />;
// }

export function Dashboard() {
  return (
    <Container>
      <Row>
        <Col sm={12} md={8} xl={8} className="dashboard-left">
          <Row>
            <DashboardGreeting />
          </Row>
          <Row>
            <DashboardApplicants />
          </Row>
        </Col>

        <Col sm={12} md={4} className="dashboard-right">
          <Row className="calendar">
            <DashboardCalendar />
          </Row>
          <Row>
            <DashboardPSched />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
