import { Table, Drawer, HoverCard } from "@mantine/core";
import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons";

import "../../App.css";
import { ApplicantsView } from "./ApplicantsDrawer/ApplicantsView";
import { ApplicantsEdit } from "./ApplicantsDrawer/ApplicantsEdit";
import { progressCheck } from "../components/display";

/*Placeholder*/
const elements = [
  {
    id: "ZS-010A",
    name: "Ayaka Kamisato",
    position: "Front-end Developer",
    status: 100,
    date_added: "2022-09-01",
    type: "Full-time (Regular)",
    email: "shirasagi@kamisato.com",
    phone: "63912345678",
    referred: "Ayato Kamisato",
  },
  {
    id: "ZS-011A",
    name: "Kaeya Alberich",
    position: "Back-end Developer",
    status: 75,
    date_added: "2022-09-01",
    type: "Part-time (Regular)",
    email: "kalberich@knights.com",
    phone: "63912345876",
    referred: "Jean Gunnhildr",
  },
  {
    id: "ZS-012A",
    name: "Sara Kujou",
    position: "UX/UI Designer",
    status: 25,
    date_added: "2022-09-01",
    type: "Full-time (Regular)",
    email: "kujousara@tenryou.com",
    phone: "63912345678",
    referred: "Raiden Ei",
  },
  {
    id: "ZS-013A",
    name: "Kazuha Kaedehara",
    position: "Back-end Developer",
    status: 50,
    date_added: "2022-09-01",
    type: "Full-time (Regular)",
    email: "kaedehara@yashiro.com",
    phone: "63912346578",
    referred: "Ayato Kamisato",
  },
];

export function ApplicantsTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const [opened, setOpened] = useState(false);
  const [applicantData, setApplicantData] = useState({});
  const [editContactId, setEditContactId] = useState(null);

  function clicked(
    appAdded,
    appName,
    appID,
    appPosition,
    appType,
    appEmail,
    appPhone,
    appReferred,
    appStatus
  ) {
    setOpened(true);

    let appData = {};
    appData["date_added"] = appAdded;
    appData["name"] = appName;
    appData["id"] = appID;
    appData["position"] = appPosition;
    appData["type"] = appType;
    appData["email"] = appEmail;
    appData["phone"] = appPhone;
    appData["referred"] = appReferred;
    appData["status"] = appStatus;

    let jsonString = JSON.stringify(Object.assign({}, appData));
    let json_obj = JSON.parse(jsonString);

    setApplicantData(json_obj);
  }

  function MantineTable() {
    const rows = elements
      .filter((val) => {
        if (searchTerm === "") {
          return val;
        } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val;
        }
      })
      .map((element) => (
        <tr key={element.name}>
          <td>
            <button
              onClick={() =>
                clicked(
                  element.date_added,
                  element.name,
                  element.id,
                  element.position,
                  element.type,
                  element.email,
                  element.phone,
                  element.referred,
                  element.status
                )
              }
              className="btn-text"
            >
              {element.id}
            </button>
          </td>
          <td>
            <div className="name-cell">{element.name}</div>
          </td>
          <td>{element.position}</td>
          <td>
            <HoverCard shadow="md">
              <HoverCard.Target>
                <div>
                  <ProgressBar
                    completed={element.status}
                    height={10}
                    customLabel={" "}
                    bgColor={"#3cc2dd"}
                    baseBgColor={"#e8e8e8"}
                  />
                </div>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                {progressCheck(element.status)}
              </HoverCard.Dropdown>
            </HoverCard>
          </td>
        </tr>
      ));

    return (
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>ID </th>
            <th>Name</th>
            <th>Position</th>
            <th>Application Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  const handleEditClick = (event, applicantData) => {
    event.preventDefault();
    setEditContactId(applicantData.id);
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    setEditContactId(null);
  };

  return (
    <>
      <Row>
        <Col>
          <Input
            icon={<IconSearch size={16} />}
            placeholder="Search..."
            radius="lg"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </Col>
      </Row>
      <MantineTable />
      <Drawer
        position="right"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Applicant Info"
        padding="xl"
        size="xl"
      >
        {editContactId === applicantData.id ? (
          <ApplicantsEdit
            applicantData={applicantData}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <ApplicantsView
            applicantData={applicantData}
            handleEditClick={handleEditClick}
          />
        )}
      </Drawer>
    </>
  );
}
