import CurrencyFormat from "react-currency-format";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DateFormat } from "../../components/display";

export function PSchedView({ scheduleData, disabled, handleEditClick }) {
  return (
    <>
      <Container>
        <Row>
          <Col className="info-title subheading">Reference No.</Col>
          <Col className="info-title subheading">Progress</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">{scheduleData.ref}</Col>
          <Col className="info-data body-sb">{scheduleData.progress}%</Col>
        </Row>

        <Row>
          <Col className="info-title subheading">PRF No.</Col>
          <Col className="info-title subheading">Criteria</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">{scheduleData.prf}</Col>
          <Col className="info-data body-sb">{scheduleData.criteria}%</Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Link</Col>
          <Col className="info-title subheading">Employee</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <a href={scheduleData.link} target="_blank">
              {scheduleData.link}
            </a>
          </Col>
          <Col className="info-data body-sb">{scheduleData.employee}</Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Target Date</Col>
          <Col className="info-title subheading">Subproject</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            {DateFormat(scheduleData.date)}
          </Col>
          <Col className="info-data body-sb">{scheduleData.subproject}</Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Amount</Col>
          <Col className="info-title subheading">Reference File</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <CurrencyFormat
              value={scheduleData.amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Col>
          <Col className="info-data body-sb">
            <a href={scheduleData.link} target="_blank">
              {scheduleData.file}
            </a>
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Remarks</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">{scheduleData.remarks}</Col>
        </Row>

        <Row>
          <Col className="emp-right">
            <button disabled={disabled} className="btn-primary">
              Resolve Payment
            </button>
            <button
              className="btn-secondary"
              onClick={(event) => handleEditClick(event, scheduleData)}
            >
              Edit
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
