import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../App.css";
import { useParams } from "react-router-dom";
import { TextInput, Select } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DatePicker, DateRangePicker } from "@mantine/dates";
import { payLabel } from "../components/display";

const teams = [
  { value: "Technical", label: "Technical" },
  { value: "Management", label: "Management" },
];

const positions = [
  { value: "Project Manager", label: "Project Manager" },
  { value: "Front-end Developer", label: "Front-end Developer" },
  { value: "Back-end Developer", label: "Back-end Developer" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "QA Engineer", label: "QA Engineer" },
  { value: "IoT Engineer", label: "IoT Engineer" },
];

export default function PayslipEdit() {
  let { id, release } = useParams();

  const [additionalEarningField, setAdditionalEarningField] = useState([]);
  const [additionalEarningValue, setAdditionalEarningValue] = useState([
    { additionalValue: "" },
  ]);
  const [additionalDeductionField, setAdditionalDeductionField] = useState([]);
  const [additionalDeductionValue, setAdditionalDeductionValue] = useState([
    { additionalValue: "" },
  ]);

  const handleAdditionalEarningFieldAdd = () => {
    setAdditionalEarningField([
      ...additionalEarningField,
      { additionalField: "" },
    ]);
  };

  const handleAdditionalDeductionFieldAdd = () => {
    setAdditionalDeductionField([
      ...additionalDeductionField,
      { additionalField: "" },
    ]);
  };
  // employee data where employee_id = id
  // part-time sample

  //   const employeeData = {
  //     id: "ZS-010",
  //     name: "Ayato Kamisato",
  //     team: "Technical",
  //     type: "Part-time (Regular)",
  //     position: "Front-end Developer",
  //     hourly_rate: 420,
  //   };

  //   const payslip_data = {
  //     id: "ZS-010",
  //     release: "2022-08-01",
  //     cutoff: "2022-07-16 to 2022-07-31",
  //     hours_worked: 35,
  //     monthly_pay: 14700,
  //   };

  // Full-Time Sample

  const employeeData = {
    id: "ZS-011",
    name: "Jean Gunnhildr",
    team: "Technical",
    type: "Full-time (Regular)",
    position: "Project Manager",
    basic_pay: 19420,
  };

  const payslip_data = {
    id: "ZS-010",
    release: "2022-08-01",
    cutoff_start: "2022-07-16",
    cutoff_end: "2022-07-31",
    hours_worked: 35,
    working_hours: 35,
    basic_pay: 19420,
    allowance: 0,
    additional_earnings:
      '[{ "13th Month Pay": 2000, "Adjustments": 1000, "More Adjustments":1000 }]',
    sss: 0,
    hdmf: 0,
    philhealth: 0,
    withholding_tax: 0,
    additional_deductions: '[{ "Adjustments": 1000, "More Adjustments":1000 }]',
  };

  function payValue() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <TextInput variant={"filled"} defaultValue={payslip_data.basic_pay} />
        </>
      );
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <TextInput
            variant={"filled"}
            defaultValue={payslip_data.monthly_pay}
          />
        </>
      );
    } else {
      return <></>;
    }
  }

  function hourLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>Total Working Hours</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return <>Hourly Rate</>;
    } else {
      return <></>;
    }
  }

  function hourValue() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <TextInput
            variant={"filled"}
            defaultValue={payslip_data.working_hours}
          />
        </>
      );
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <TextInput
            variant={"filled"}
            defaultValue={employeeData.hourly_rate}
          />
        </>
      );
    } else {
      return <></>;
    }
  }

  function defaultEarnings() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">Allowance</Row>
          <Row className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={payslip_data.allowance}
            />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  function defaultDeductions() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">SSS</Row>
          <Row className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={payslip_data.sss} />
          </Row>
          <Row className="info-title subheading">HDMF</Row>
          <Row className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={payslip_data.hdmf} />
          </Row>
          <Row className="info-title subheading">PhilHealth</Row>
          <Row className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={payslip_data.philhealth}
            />
          </Row>
          <Row className="info-title subheading">Withholding Taxes</Row>
          <Row className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={payslip_data.withholding_tax}
            />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  function additionalEarnings() {
    let additionalData = payslip_data.additional_earnings;
    let additionalEarnings = JSON.parse(additionalData);

    return (
      <>
        {Object.keys(additionalEarnings[0]).map((index) => {
          return (
            <>
              <Row className="info-title subheading">
                <TextInput variant={"filled"} defaultValue={index} size="xs" />
              </Row>
              <Row className="info-data body-sb">
                <TextInput
                  variant={"filled"}
                  defaultValue={additionalEarnings[0][index]}
                />
              </Row>
            </>
          );
        })}
      </>
    );
  }

  function additionalDeductions() {
    let additionalData = payslip_data.additional_deductions;
    let additionalDeductions = JSON.parse(additionalData);

    return (
      <>
        {Object.keys(additionalDeductions[0]).map((index) => {
          return (
            <>
              <Row className="info-title subheading">
                <TextInput variant={"filled"} defaultValue={index} size="xs" />
              </Row>
              <Row className="info-data body-sb">
                <TextInput
                  variant={"filled"}
                  defaultValue={additionalDeductions[0][index]}
                />
              </Row>
            </>
          );
        })}
      </>
    );
  }

  const [cutoff, setCutoff] = useState([
    new Date(payslip_data.cutoff_start),
    new Date(payslip_data.cutoff_end),
  ]);

  return (
    <Container>
      <Row className="button-ur">
        <Col className="ps-right">
          <button className="btn-primary">Save</button>
          <Link
            className="btn-secondary"
            to={`/employee/${payslip_data.id}/payslip/${payslip_data.release}`}
          >
            Cancel
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col className="info-title subheading">Name</Col>
          </Row>
          <Row>
            <Col className="info-name heading3">{employeeData.name}</Col>
          </Row>
          <Row>
            <Col className="info-title subheading">{id}</Col>
          </Row>
        </Col>
        <Col>
          <Row className="info-title subheading">Payslip For</Row>
          <Row className="info-name heading3">
            <DatePicker defaultValue={new Date(release)} />
          </Row>
        </Col>
        <Col>
          <Row className="info-title subheading">Cutoff</Row>
          <Row className="info-name heading3">
            <DateRangePicker value={cutoff} onChange={setCutoff} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Row className="info-data heading2 space-one">Basic Information</Row>
          <Row className="info-title subheading">Position</Row>
          <Row className="info-data body-sb">
            <Select
              searchable
              defaultValue={employeeData.position}
              data={positions}
            />
          </Row>
          <Row className="info-title subheading">Team</Row>
          <Row className="info-data body-sb">
            <Select searchable defaultValue={employeeData.team} data={teams} />
          </Row>
          <Row className="info-title subheading">Hours Worked</Row>
          <Row className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={payslip_data.hours_worked}
            />
          </Row>
          <Row className="info-title subheading">{hourLabel()}</Row>
          <Row className="info-data body-sb">{hourValue()}</Row>
        </Col>
        <Col md={4}>
          <Row className="info-data heading2 space-one">Earnings</Row>
          <Row className="info-title subheading">
            {payLabel(employeeData.type)}
          </Row>
          <Row className="info-data body-sb">{payValue()}</Row>
          {defaultEarnings()}
          {payslip_data.additional_earnings && additionalEarnings()}
          {additionalEarningField.map((singleEarningField, index) => (
            <>
              <Row className="info-title subheading">
                <TextInput
                  variant={"filled"}
                  placeholder="Additional Earning Name"
                  size="xs"
                />
              </Row>
              <Row className="info-data body-sb">
                <TextInput
                  variant={"filled"}
                  placeholder="Additional Earning Value"
                />
              </Row>
            </>
          ))}
          <Row>
            <Col className="d-flex justify-content-center">
              <button
                className="btn-primary"
                onClick={handleAdditionalEarningFieldAdd}
              >
                <IconCirclePlus /> Add Earning
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="info-data heading2 space-one"> Deductions</Row>
          {defaultDeductions()}
          {payslip_data.additional_deductions && additionalDeductions()}
          {additionalDeductionField.map((singleDeductionField, index) => (
            <>
              <Row className="info-title subheading">
                <TextInput
                  variant={"filled"}
                  placeholder="Additional Deduction Name"
                  size="xs"
                />
              </Row>
              <Row className="info-data body-sb">
                <TextInput
                  variant={"filled"}
                  placeholder="Additional Deduction Value"
                />
              </Row>
            </>
          ))}
          <Row>
            <Col className="d-flex justify-content-center">
              <button
                className="btn-primary"
                onClick={handleAdditionalDeductionFieldAdd}
              >
                <IconCirclePlus /> Add Deduction
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
