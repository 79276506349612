import { TextInput, Select, Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const employee_list = [
  { value: "Diluc Ragnvindr", label: "Diluc Ragnvindr" },
  { value: "Albedo Kreideprinz", label: "Albedo Kreideprinz" },
  { value: "Klee Dodoco", label: "Klee Dodoco" },
];

const project_list = [
  { value: "ANM", label: "ANM" },
  { value: "GEO", label: "GEO" },
  { value: "ELT", label: "ELT" },
  { value: "DND", label: "DND" },
  { value: "HYD", label: "HYD" },
  { value: "PYR", label: "PYR" },
  { value: "CRY", label: "CRY" },
];

export function PSchedEdit({ scheduleData, handleCancelClick }) {
  return (
    <>
      <Container>
        <Row>
          <Col className="info-title subheading">Reference No.</Col>
          <Col className="info-title subheading">Progress</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">{scheduleData.ref}</Col>
          <Col className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={scheduleData.progress}
            />
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">PRF No.</Col>
          <Col className="info-title subheading">Criteria</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={scheduleData.prf} />
          </Col>
          <Col className="info-data body-sb">
            <TextInput
              variant={"filled"}
              defaultValue={scheduleData.criteria}
            />
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Link</Col>
          <Col className="info-title subheading">Employee</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={scheduleData.link} />
          </Col>
          <Col className="info-data body-sb">
            <Select
              searchable
              defaultValue={scheduleData.employee}
              data={employee_list}
            />
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Target Date</Col>
          <Col className="info-title subheading">Subproject</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <DatePicker defaultValue={new Date(scheduleData.date)} />
          </Col>
          <Col className="info-data body-sb">
            <Select
              searchable
              defaultValue={scheduleData.subproject}
              data={project_list}
            />
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Amount</Col>
          <Col className="info-title subheading">Reference File</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={scheduleData.amount} />
          </Col>
          <Col className="info-data body-sb">
            <TextInput variant={"filled"} defaultValue={scheduleData.file} />
          </Col>
        </Row>

        <Row>
          <Col className="info-title subheading">Remarks</Col>
        </Row>
        <Row>
          <Col className="info-data body-sb">
            <Textarea defaultValue={scheduleData.remarks} />
          </Col>
        </Row>

        <Row>
          <Col className="app-right">
            <button className="btn-primary">Save</button>
            <button
              className="btn-secondary"
              onClick={(event) => handleCancelClick(event)}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
