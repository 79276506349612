import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

export function ApplicantsArchiveHead() {
  const items = [
    { title: "Applicant List", href: "/applicant" },
    { title: "Applicant Archive", href: "/applicant/app-archive" },
  ].map((item, index) => (
    <Link to={item.href} key={index} className="heading3 remove-deco">
      {item.title}
    </Link>
  ));

  return (
    <>
      <Row>
        <Col md={6} className="app-left">
          <h2>
            <Breadcrumbs>{items}</Breadcrumbs>
          </h2>

          <h2 className="subheading">
            This is the list of all archived applicants
          </h2>
        </Col>
        <Col md={6} className="app-right">
          <button className="btn-secondary">Refresh</button>
        </Col>
      </Row>
    </>
  );
}
