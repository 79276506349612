import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../App.css";
import { Link, useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { Breadcrumbs } from "@mantine/core";
import { DateFormat } from "../components/display";

export default function PayslipView() {
  let { id, release } = useParams();

  const items = [
    { title: "Employee List", href: "/employee" },
    { title: "Payslips", href: `/employee/{:id}/payslip` },
    {
      title: "Payslip View",
    },
  ].map((item, index) => (
    <Link to={item.href} key={index} className="heading3 remove-deco">
      {item.title}
    </Link>
  ));

  // employee data where employee_id = id
  // part-time sample

  // const employeeData = {
  //   id: "ZS-010",
  //   name: "Ayato Kamisato",
  //   team: "Technical",
  //   type: "Part-time (Regular)",
  //   position: "Front-end Developer",
  //   hourly_rate: 420,
  // };

  // const payslip_data = {
  //   id: "ZS-010",
  //   release: "2022-08-01",
  //   cutoff_start: "2022-07-16",
  //   cutoff_end: "2022-07-31",
  //   hours_worked: 35,
  //   monthly_pay: 14700,
  // };

  // Full-Time Sample

  const employeeData = {
    id: "ZS-011",
    name: "Jean Gunnhildr",
    team: "Technical",
    type: "Full-time (Regular)",
    position: "Project Manager",
    basic_pay: 19420,
  };

  const payslip_data = {
    id: "ZS-010",
    release: "2022-08-01",
    cutoff_start: "2022-07-16",
    cutoff_end: "2022-07-31",
    hours_worked: 35,
    working_hours: 35,
    basic_pay: 19420,
    allowance: 0,
    additional_earnings:
      '[{ "13th Month Pay": 2000, "Adjustments": 1000, "More Adjustments":1000 }]',
    sss: 0,
    hdmf: 0,
    philhealth: 0,
    withholding_tax: 0,
    additional_deductions: '[{ "Adjustments": 1000, "More Adjustments":1000 }]',
  };

  function payLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>Basic Pay</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return <>Monthly Pay</>;
    } else {
      return <></>;
    }
  }

  function payValue() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <CurrencyFormat
            value={payslip_data.basic_pay}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </>
      );
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <CurrencyFormat
            value={payslip_data.monthly_pay}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </>
      );
    } else {
      return <></>;
    }
  }

  function hourLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>Total Working Hours</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return <>Hourly Rate</>;
    } else {
      return <></>;
    }
  }

  function hourValue() {
    if (employeeData.type === "Full-time (Regular)") {
      return <>{payslip_data.working_hours}</>;
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <CurrencyFormat
            value={employeeData.hourly_rate}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₱"}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </>
      );
    } else {
      return <></>;
    }
  }

  function defaultEarnings() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">Allowance</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={payslip_data.allowance}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  function defaultDeductions() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <Row className="info-title subheading">SSS</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={payslip_data.sss}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Row>
          <Row className="info-title subheading">HDMF</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={payslip_data.hdmf}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Row>
          <Row className="info-title subheading">PhilHealth</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={payslip_data.philhealth}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Row>
          <Row className="info-title subheading">Withholding Taxes</Row>
          <Row className="info-data body-sb">
            <CurrencyFormat
              value={payslip_data.withholding_tax}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Row>
        </>
      );
    } else {
      return <></>;
    }
  }

  function additionalEarnings() {
    let additionalData = payslip_data.additional_earnings;
    let additionalEarnings = JSON.parse(additionalData);

    return (
      <>
        {Object.keys(additionalEarnings[0]).map((index) => {
          return (
            <>
              <Row className="info-title subheading">{index}</Row>
              <Row className="info-data body-sb">
                <CurrencyFormat
                  value={additionalEarnings[0][index]}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₱"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Row>
            </>
          );
        })}
      </>
    );
  }

  function additionalDeductions() {
    let additionalData = payslip_data.additional_deductions;
    let additionalDeductions = JSON.parse(additionalData);

    return (
      <>
        {Object.keys(additionalDeductions[0]).map((index) => {
          return (
            <>
              <Row className="info-title subheading">{index}</Row>
              <Row className="info-data body-sb">
                <CurrencyFormat
                  value={additionalDeductions[0][index]}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₱"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Row>
            </>
          );
        })}
      </>
    );
  }

  return (
    <Container>
      <Row className="button-ur">
        <Col>
          <Breadcrumbs>{items}</Breadcrumbs>
        </Col>
        <Col className="ps-right">
          <button className="btn-primary">Generate Payslip</button>
          <Link
            className="btn-secondary"
            to={`/employee/${payslip_data.id}/payslip/${payslip_data.release}/edit`}
          >
            Edit
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col className="info-title subheading">Name</Col>
          </Row>
          <Row>
            <Col className="info-name heading3">{employeeData.name}</Col>
          </Row>
          <Row>
            <Col className="info-title subheading">{id}</Col>
          </Row>
        </Col>
        <Col>
          <Row className="info-title subheading">Payslip For</Row>
          <Row className="info-name heading3">{DateFormat(release)}</Row>
        </Col>
        <Col>
          <Row className="info-title subheading">Cutoff</Row>
          <Row className="info-name heading3">
            {DateFormat(payslip_data.cutoff_start)} –
            {DateFormat(payslip_data.cutoff_end)}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Row className="info-data heading2 space-one">Basic Information</Row>
          <Row className="info-title subheading">Position</Row>
          <Row className="info-data body-sb">{employeeData.position}</Row>
          <Row className="info-title subheading">Team</Row>
          <Row className="info-data body-sb">{employeeData.team}</Row>
          <Row className="info-title subheading">Hours Worked</Row>
          <Row className="info-data body-sb">{payslip_data.hours_worked}</Row>
          <Row className="info-title subheading">{hourLabel()}</Row>
          <Row className="info-data body-sb">{hourValue()}</Row>
        </Col>
        <Col md={4}>
          <Row className="info-data heading2 space-one">Earnings</Row>
          <Row className="info-title subheading">{payLabel()}</Row>
          <Row className="info-data body-sb">{payValue()}</Row>
          {defaultEarnings()}
          {payslip_data.additional_earnings && additionalEarnings()}
        </Col>
        <Col md={4}>
          <Row className="info-data heading2 space-one"> Deductions</Row>
          {defaultDeductions()}
          {payslip_data.additional_deductions && additionalDeductions()}
        </Col>
      </Row>
    </Container>
  );
}
