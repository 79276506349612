import { Table, HoverCard } from "@mantine/core";
import ProgressBar from "@ramonak/react-progress-bar";
import { progressCheck } from "../components/display";

/*Placeholder*/
const elements = [
  {
    name: "Ayaka Kamisato",
    position: "Front-end Developer",
    status: 100,
  },
  {
    name: "Kaeya Alberich",
    position: "Back-end Developer",
    status: 75,
  },
  { name: "Sara Kujou", position: "UX/UI Designer", status: 25 },
  {
    name: "Kazuha Kaedehara",
    position: "Back-end Developer",
    status: 50,
  },
];

function MantineTable() {
  const rows = elements.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
      <td>{element.position}</td>
      <td>
        <HoverCard shadow="md">
          <HoverCard.Target>
            <div>
              <ProgressBar
                completed={element.status}
                height={10}
                customLabel={" "}
                bgColor={"#3cc2dd"}
                baseBgColor={"#e8e8e8"}
              />
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            {progressCheck(element.status)}
          </HoverCard.Dropdown>
        </HoverCard>
      </td>
    </tr>
  ));

  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Position</th>
          <th>Application Status</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export function DashboardApplicants() {
  return (
    <>
      <div className="heading2">Applicants Preview</div>
      <MantineTable />
    </>
  );
}
