import { Tabs, TextInput, Select } from "@mantine/core";
import Avatar from "react-avatar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const project_list = [
  { value: "ANM", label: "ANM" },
  { value: "GEO", label: "GEO" },
  { value: "ELT", label: "ELT" },
  { value: "DND", label: "DND" },
  { value: "HYD", label: "HYD" },
  { value: "PYR", label: "PYR" },
  { value: "CRY", label: "CRY" },
];

const employee_list = [
  { value: "Diluc Ragnvindr", label: "Diluc Ragnvindr" },
  { value: "Lisa Minci", label: "Lisa Minci" },
  { value: "Ayato Kamisato", label: "Ayato Kamisato" },
  { value: "Jean Gunnhildr", label: "Jean Gunnhildr" },
  { value: "Venti Barbatos", label: "Venti Barbatos" },
  { value: "Zhongli Morax", label: "Zhongli Morax" },
];

export function PSchedAdd() {
  return (
    <>
      <Container>
        <Select
          searchable
          label="Subproject"
          placeholder="Select Subproject..."
          data={project_list}
        />
        <Select
          searchable
          label="Employee"
          placeholder="Select Employee..."
          data={employee_list}
        />
        <TextInput label="Total Project Compensation" />
        <TextInput label="No. of Payments" />
        <TextInput label="SOW & JO Reference" />
        <Row className="ps-left">
          <button className="btn-primary">Save</button>
        </Row>
      </Container>
    </>
  );
}
