import { useState } from "react";
import { Calendar } from "@mantine/dates";
import { Indicator } from "@mantine/core";
import { DateFormat } from "../components/display";
import { Table } from "@mantine/core";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const upcomingPayment = [
  { name: "Albedo Kreideprinz", date: "2022 September 12" },
  { name: "Klee Dodoco", date: "2022 September 16" },
];

const test = ["2022-09-12", "2022-09-16"];

const event = (day, eventDate, indicator) => {
  for (let i = 0; i < eventDate.length; i++) {
    if (day !== DateFormat(eventDate[i])) {
      indicator = true;
    } else {
      indicator = false;
    }
    if (day === DateFormat(eventDate[i])) {
      break;
    }
  }
  return indicator;
};

function UpcomingTable() {
  const rows = upcomingPayment.map((upcoming) => (
    <tr>
      <td>{upcoming.name}</td>
      <td>{upcoming.date}</td>
    </tr>
  ));
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th colSpan={2} align={"center"}>
            Upcoming Payments
          </th>
        </tr>
        <tr>
          <th>Employee</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export function MantineCalendar() {
  const [value, setValue] = useState(null);
  const rows = upcomingPayment
    .filter((val) => {
      if (value === null) {
        return val;
      } else if (DateFormat(val.date) === DateFormat(value)) {
        return val;
      }
    })
    .map((upcoming) => (
      <tr>
        <td>{upcoming.name}</td>
        <td>{upcoming.date}</td>
      </tr>
    ));

  return (
    <>
      <Row>
        <Col>
          <Calendar
            value={value}
            onChange={setValue}
            renderDay={(date) => {
              const day = date.getDate();

              return (
                <>
                  <Indicator
                    size={6}
                    color="red"
                    offset={8}
                    disabled={event(DateFormat(date), test)}
                  >
                    <div>{day}</div>
                  </Indicator>
                </>
              );
            }}
          />
        </Col>

        <Col>
          <Table highlightOnHover>
            <thead>
              <tr>
                <th colSpan={2} align={"center"}>
                  Upcoming Payments
                </th>
              </tr>
              <tr>
                <th>Employee</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export function DashboardCalendar() {
  return (
    <>
      <div className="calendar">
        <MantineCalendar />
      </div>
      {/* <div>
        <UpcomingTable />
      </div> */}
    </>
  );
}
