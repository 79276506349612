import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Textarea } from "@mantine/core";
import { DateFormat } from "../../../components/display";

export default function ProgressFinalView({ applicantProgress }) {
  return (
    <>
      <Container className="borderstyle-mix">
        <Row>
          <Col>
            <Container>
              Interview Schedule: {DateFormat(applicantProgress.final_schedule)}
            </Container>
          </Col>
          <Col>
            <Container className="ps-right-one">
              <a href={applicantProgress.files} target="_blank">
                Interview Files
              </a>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Textarea
          disabled
          defaultValue={applicantProgress.final_assessment}
          label="Interview Assessment"
          placeholder="Enter Text Here..."
          minRows={10}
          className="text-area-padding"
        />
      </Container>
    </>
  );
}
