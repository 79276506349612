import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Textarea } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

export default function ProgressFinalEdit({
  applicantProgress,
  handleCancelClick,
}) {
  return (
    <>
      <Container className="borderstyle-mix">
        <Row>
          <Col>
            <Container>
              <DatePicker
                defaultValue={new Date(applicantProgress.final_schedule)}
                label="Interview Schedule"
                className="text-area-padding"
              />
            </Container>
          </Col>
          <Col>
            <Container>
              <Textarea
                defaultValue={applicantProgress.files}
                label="Interview Files"
                minRows={1}
                className="text-area-padding"
              />
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Textarea
          defaultValue={applicantProgress.final_assessment}
          label="Interview Assessment"
          placeholder="Enter Text Here..."
          minRows={10}
          className="text-area-padding"
        />
      </Container>
      <Container>
        <Row>
          <Col className="app-right">
            <button className="btn-primary">Save</button>
            <button
              className="btn-secondary"
              onClick={() => handleCancelClick(false)}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
