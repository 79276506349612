import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";

export function PSchedHead() {
  return (
    <>
      <Row>
        <Col className="ps-left">
          <h1 className="heading3">Payment Schedule</h1>
          <h2 className="subheading">
            This is the list of all payment schedules
          </h2>
        </Col>
      </Row>
    </>
  );
}
