import { Link, NavLink } from "react-router-dom";
import { createStyles, Navbar, Group, Container } from "@mantine/core";
import {
  IconHome,
  IconUser,
  IconCalendar,
  IconUsers,
  IconLogout,
} from "@tabler/icons";
import zipher_logo from "../img/zipher-logo.png";
import "../App.css";
import { selectUser, logout } from "../features/userSlice";
import { MantineProvider } from "@mantine/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${icon}`]: {
        color: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).color,
      },
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
    },
  };
});

const data = [
  { link: "/dash", label: "Dashboard", icon: IconHome },
  { link: "/employee", label: "Employee", icon: IconUser },
  { link: "/payment", label: "Payment Schedule", icon: IconCalendar },
  { link: "/applicant", label: "Applicants", icon: IconUsers },
];

export function SideNav() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const handleLogout = (e) => {
    localStorage.clear();
    dispatch(logout());
    navigate("/login");
  };

  const links = data.map((item) => (
    <NavLink
      className={({ isActive }) =>
        isActive ? classes.linkActive : classes.link
      }
      to={item.link}
      key={item.label}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  ));

  return (
    <Navbar height={700} width={{ sm: 300 }} p="md">
      <Navbar.Section grow>
        <Group className={classes.header} position="apart">
          <Container>
            <img src={zipher_logo} alt="Zipher Logo" />
          </Container>
        </Group>
        {links}
        <Navbar.Section className={classes.header}></Navbar.Section>
        <Navbar.Section className={classes.header}>
          <Link
            to="/login"
            className={classes.link}
            onClick={() => handleLogout()}
          >
            <IconLogout className={classes.linkIcon} stroke={1.5} />
            <span>Logout</span>
          </Link>
        </Navbar.Section>
      </Navbar.Section>
    </Navbar>
  );
}
