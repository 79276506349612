import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Textarea } from "@mantine/core";

export default function ProgressResumeEdit({
  applicantProgress,
  handleCancelClick,
}) {
  return (
    <Container>
      <Row>
        <Col>Admin Officer</Col>
        <Col>Technical Manager </Col>
        <Col>General Manager</Col>
      </Row>
      <Row>
        <Col>
          <Textarea
            defaultValue={applicantProgress.resume_admin}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
        <Col>
          <Textarea
            defaultValue={applicantProgress.resume_technical}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
        <Col>
          <Textarea
            defaultValue={applicantProgress.resume_general}
            placeholder="Enter Text Here..."
            minRows={10}
          />
        </Col>
      </Row>
      <Row>
        <Col className="app-right">
          <button className="btn-primary">Save</button>
          <button
            className="btn-secondary"
            onClick={() => handleCancelClick(false)}
          >
            Cancel
          </button>
        </Col>
      </Row>
    </Container>
  );
}
