import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PSchedHead } from "../PaymentSched/PSchedHead";
import { PSchedTable } from "../PaymentSched/PSchedTable";

export function PaymentSchedule() {
  return (
    <Container>
      <Row>
        <Col>
          <PSchedHead />
        </Col>
      </Row>
      <Row>
        <Col>
          <PSchedTable />
        </Col>
      </Row>
    </Container>
  );
}
