import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { Modal } from "@mantine/core";
import { AddNewPaySlip } from "../components/Modals";
import { Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";

export function PayslipHead({ id }) {
  const employee_details = {
    id: "ZS-011",
    name: "Jean Gunnhildr",
    team: "Technical",
    type: "Full-time (Regular)",
    position: "Project Manager",
    basic_pay: 19420,
  };
  // type position team
  const items = [
    { title: "Employee List", href: "/employee" },
    { title: "Payslips" },
  ].map((item, index) => (
    <Link to={item.href} key={index} className="heading3 remove-deco">
      {item.title}
    </Link>
  ));

  const [add, setAdd] = useState(false);
  return (
    <>
      <Row>
        <Col>
          <h2></h2>
          <Breadcrumbs>{items}</Breadcrumbs>
        </Col>
      </Row>
      <Row>
        <Col className="emp-left">
          <h1 className="heading3">{employee_details.name}'s Pay Slips</h1>
          <h2 className="subheading">
            This is the list of all {employee_details.name}'s pay slips
          </h2>
        </Col>
        <Col className="emp-right">
          <button className="btn-primary" onClick={() => setAdd(true)}>
            Add New Pay Slip
          </button>
        </Col>
      </Row>
      <Modal
        size={"xl"}
        opened={add}
        onClose={() => setAdd(false)}
        title="Add New Pay Slip"
      >
        <AddNewPaySlip employeeData={employee_details} setAdd={setAdd} />
      </Modal>
    </>
  );
}
