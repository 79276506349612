import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressView from "./ProgressView/ProgressView";
import { Link } from "react-router-dom";
import { useState } from "react";
import ProgressEdit from "./ProgressView/ProgressEdit";
import { Breadcrumbs } from "@mantine/core";

export function ProgressViewHead() {
  const [edit, setEdit] = useState(false);
  const handleEditClick = () => {
    setEdit(true);
  };
  const handleCancelClick = () => {
    setEdit(false);
  };
  const items = [
    { title: "Applicant List", href: "/applicant" },
    { title: "Applicant Progress" },
  ].map((item, index) => (
    <Link to={item.href} key={index} className="heading3 remove-deco">
      {item.title}
    </Link>
  ));

  return (
    <>
      <Row>
        <Col md={6} className="app-left">
          <h1 className="heading3">
            {" "}
            <Breadcrumbs>{items}</Breadcrumbs>
          </h1>
          <h2 className="subheading">This is the progress of an applicant</h2>
        </Col>
        <Col className="space-five emp-right">
          <button className="btn-primary" onClick={() => handleEditClick()}>
            Edit
          </button>
        </Col>

        <Row>
          {edit === false ? (
            <ProgressView />
          ) : (
            <ProgressEdit handleCancelClick={handleCancelClick} />
          )}
        </Row>
      </Row>
    </>
  );
}
