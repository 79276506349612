import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export function ApplicantsHead() {
  return (
    <>
      <Row>
        <Col md={6} className="app-left">
          <h1 className="heading3">Applicant List</h1>
          <h2 className="subheading">This is the list of all applicants</h2>
        </Col>
        <Col md={6} className="app-right">
          <Link className="btn-primary" to={"/applicant/app-archive"}>
            View Applicant Archive
          </Link>
          <button className="btn-secondary">Refresh</button>
        </Col>
      </Row>
    </>
  );
}
