import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Textarea } from "@mantine/core";
import { DateFormat } from "../../../components/display";

export default function ProgressTechnicalView({ applicantProgress }) {
  return (
    <>
      <Container className="borderstyle-mix">
        <Row>
          <Col>
            <Container>
              Interview Schedule:{" "}
              {DateFormat(applicantProgress.technical_schedule)}
            </Container>
          </Col>
          <Col>
            <Container className="ps-right-one">
              <a href={applicantProgress.files} target="_blank">
                Interview Files
              </a>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <Textarea
              disabled
              label="Interview Assessment"
              placeholder="Enter Text Here..."
              defaultValue={applicantProgress.technical_assessment}
              minRows={10}
              className="text-area-padding"
            />
          </Col>
          <Col>
            <Textarea
              disabled
              label="Exam Score"
              defaultValue={applicantProgress.technical_score}
              minRows={10}
              className="text-area-padding"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
