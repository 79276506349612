import { Tabs, Modal } from "@mantine/core";
import Avatar from "react-avatar";
import CurrencyFormat from "react-currency-format";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useState } from "react";
import { DeleteEmployeeDialog } from "../../components/Modals";
import { DateFormat } from "../../components/display";

export function EmployeesArchiveView({ employeeData, handleEditClick }) {
  const [deleteEmp, setDeleteEmp] = useState(false);

  function deleteEmployee() {
    setDeleteEmp(true);
  }

  function payLabel() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <div>Basic Pay</div>
        </>
      );
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <div>Hourly Rate</div>
        </>
      );
    } else {
      return <></>;
    }
  }

  function payValue() {
    if (employeeData.type === "Full-time (Regular)") {
      return (
        <>
          <div>
            <CurrencyFormat
              value={employeeData.basic_pay}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </>
      );
    } else if (employeeData.type === "Part-time (Regular)") {
      return (
        <>
          <div>
            <CurrencyFormat
              value={employeeData.hourly_rate}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₱"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <Avatar
              name={employeeData.name}
              size="110"
              round={true}
              textSizeRatio={3}
            />
          </Col>
          <Col>
            <Row>
              <Col className="info-title subheading">Name</Col>
            </Row>
            <Row>
              <Col className="info-name heading3">{employeeData.name}</Col>
            </Row>
            <Row>
              <Col className="info-title subheading">{employeeData.id}</Col>
            </Row>
            <Row>
              <Col className="info-title subheading">
                Added on {DateFormat(employeeData.date_added)}
              </Col>
            </Row>
          </Col>
          <Col className="emp-right">
            <button className="btn-archive" onClick={() => deleteEmployee()}>
              Delete Employee
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs defaultValue="general">
              <Tabs.List>
                <Tabs.Tab value="general">General Information</Tabs.Tab>
                <Tabs.Tab value="bank">Bank Details</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="general" pt="xs">
                <Container>
                  <Row>
                    <Col className="info-title subheading">Position</Col>
                    <Col className="info-title subheading">Team</Col>
                  </Row>

                  <Row>
                    <Col className="info-data body-sb">
                      {employeeData.position}
                    </Col>
                    <Col className="info-data body-sb">{employeeData.team}</Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Email</Col>
                    <Col className="info-title subheading">Employee Type</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      {employeeData.email}
                    </Col>
                    <Col className="info-data body-sb">{employeeData.type}</Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Phone Number</Col>
                    <Col className="info-title subheading">{payLabel()}</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <CurrencyFormat
                        value={employeeData.phone}
                        displayType={"text"}
                        format={"+## ### ### ####"}
                      />
                    </Col>
                    <Col className="info-data body-sb">{payValue()}</Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">HR Drive</Col>
                    <Col className="info-title subheading">Referred By</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <a href={employeeData.file} target="_blank">
                        {employeeData.file}
                      </a>
                    </Col>
                    <Col className="info-data body-sb">
                      {employeeData.referred}
                    </Col>
                  </Row>
                </Container>
              </Tabs.Panel>

              <Tabs.Panel value="bank" pt="xs">
                <Container>
                  <Row>
                    <Col className="info-title subheading">Bank Name</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      {employeeData.bank_name}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Account Name</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      {employeeData.acc_name}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="info-title subheading">Account Number</Col>
                  </Row>
                  <Row>
                    <Col className="info-data body-sb">
                      <CurrencyFormat
                        value={employeeData.acc_num}
                        displayType={"text"}
                        format={"#### #### ####"}
                      />
                    </Col>
                  </Row>
                </Container>
              </Tabs.Panel>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col className="emp-right">
            <button
              className="btn-primary"
              onClick={(event) => handleEditClick(event, employeeData)}
            >
              Edit Information
            </button>
            <Link className="btn-secondary" to={"/payslip"}>
              View Pay Slips
            </Link>
          </Col>
        </Row>
      </Container>

      <Modal
        centered
        opened={deleteEmp}
        onClose={() => setDeleteEmp(false)}
        title="Delete Employee"
      >
        <DeleteEmployeeDialog
          name={employeeData.name}
          setDeleteEmp={setDeleteEmp}
        />
      </Modal>
    </>
  );
}
